import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import ErrorForm from '../../components/forms/ErrorForm';
import { connexionSchema, connexionType } from '../../schema/LoginSchema';
import UsersService from '../../services/users.service';
import LoginForm from './forms/LoginForm';
import TFAForm from './forms/TFAForm';
import UpdateSkemeetPasswordForm from './forms/UpdateSkemeetPasswordForm';
import DoubleAuthSmsModal from '../DashboardApps/DoubleAuthSmsModal';

const LoginComponent = () => {
  const usersService = new UsersService();
  const searchQueryParams = useSearchParams()[0];
  const [pageStep, setPageStep] = useState<string>('connexion');
  const [isDoubleAuthEmail, setIsDoubleAuthEmail] = useState<boolean>(false);
  const [redirectUrlSaved, setRedirectUrlSaved] = useState<boolean>(false);
  const [skemeetUserInfos, setSkemeetUserInfos] = useState<{
    temporaryAccessToken: string;
    firstname: string;
    lastname: string;
    cellPhone: string;
  }>({
    temporaryAccessToken: '',
    firstname: '',
    lastname: '',
    cellPhone: '',
  });
  const [isDoubleAuthModalOpen, setIsDoubleAuthModalOpen] = useState(false);
  const navigate = useNavigate();

  const connexionMethods = useForm<connexionType>({
    resolver: yupResolver(connexionSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickNameOrEmail: '',
      password: '',
    },
  });

  const redirect = async (openDoubleAuthModal: boolean = true) => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (openDoubleAuthModal) {
      const { data: user } = await usersService.getUserData();
      if (user.doubleAuthEmailActivated) return setIsDoubleAuthModalOpen(true);
    }

    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      navigate('/dashboard/apps');
    }
  };

  const setTokenAndRedirect = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    redirect();
  };

  useEffect(() => {
    (async () => {
      const localStorageRedirectUrl = searchQueryParams.get('localStorageRedirectUrl');
      const redirectUrl = localStorageRedirectUrl ? localStorage.getItem('redirectUrl') : searchQueryParams.get('redirectUrl');
      if (!localStorageRedirectUrl) {
        localStorage.setItem('redirectUrl', redirectUrl ? decodeURIComponent(redirectUrl) : '');
      }
      setRedirectUrlSaved(true);

      try {
        await usersService.getUserData(false);
      } catch (error) {
        return;
      }
      // Garde fou pour éviter les boucles infinis entre les apps et la page de connexion
      const lastRedirectWithTenSecond = new Date(localStorage.getItem('lastRedirect') || '0');
      lastRedirectWithTenSecond.setSeconds(lastRedirectWithTenSecond.getSeconds() + 10);

      if (lastRedirectWithTenSecond > new Date()) {
        navigate(`/logout`);
      } else {
        localStorage.setItem('lastRedirect', new Date().toISOString());

        redirect();
      }
    })();
  }, []);

  return (
    <>
      {redirectUrlSaved && (
        <div className='z-[10] w-full rounded-lg bg-white p-10'>
          {
            {
              connexion: (
                <LoginForm
                  setPageStep={setPageStep}
                  setSkemeetUserInfos={setSkemeetUserInfos}
                  setTokenAndRedirect={setTokenAndRedirect}
                  connexionMethods={connexionMethods}
                  setIsDoubleAuthEmail={setIsDoubleAuthEmail}
                />
              ),
              connexionStepTwo: (
                <TFAForm
                  setPageStep={setPageStep}
                  setTokenAndRedirect={setTokenAndRedirect}
                  connexionMethods={connexionMethods}
                  setIsDoubleAuthEmail={setIsDoubleAuthEmail}
                  isDoubleAuthEmail={isDoubleAuthEmail}
                />
              ),
              updateSkemeetPassword: (
                <UpdateSkemeetPasswordForm
                  setTokenAndRedirect={setTokenAndRedirect}
                  skemeetUserInfos={skemeetUserInfos}
                  connexionMethods={connexionMethods}
                />
              ),
              error: <ErrorForm onClick={() => setPageStep('connexion')} />,
            }[pageStep]
          }
        </div>
      )}
      <Seo title={'Connexion'} />
      <DoubleAuthSmsModal
        isOpen={isDoubleAuthModalOpen}
        onClose={() => {
          setIsDoubleAuthModalOpen(false);
          redirect(false);
        }}
      />
    </>
  );
};

export default LoginComponent;
