import { useNavigate } from 'react-router-dom';
import AuthSettingsItem from './authSettings/AuthSettingsItem';
import { UserInfo } from '../../constants/types';
import { UserContext } from '../../components/common/PrivateRoute';
import { useContext } from 'react';

type DashboardAuthenticationProps = {
  setShowContactForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const DashboardAuthenticationComponent: React.FC<DashboardAuthenticationProps> = ({ setShowContactForm }) => {
  const navigate = useNavigate();
  const user = useContext<UserInfo | null>(UserContext);

  return (
    <>
      {user && (
        <>
          <AuthSettingsItem
            title='Adresse e-mail'
            description='Permet de recevoir des informations et instructions en cas de problème ou modification de votre compte.'
            fieldType='UPDATED'
            actionDate={user?.emailUpdatedAt}
            borderBottom={true}
            actionOnClick={() => navigate(`/dashboard/authentication/email`)}
          />
          <AuthSettingsItem
            title='Identifiant'
            description='Couplé à votre mot de passe, vous permet de vous connecter à votre compte.'
            fieldType='UPDATED'
            actionDate={user?.nickNameUpdatedAt}
            borderBottom={true}
            actionOnClick={() => navigate(`/dashboard/authentication/nickName`)}
          />
          <AuthSettingsItem
            title='Mot de passe'
            description='Permet de confirmer votre identité à la connexion.'
            fieldType='UPDATED'
            actionDate={user?.passwordUpdatedAt}
            borderBottom={true}
            actionOnClick={() => navigate(`/dashboard/authentication/password`)}
          />
          <AuthSettingsItem
            title='Authentification à double facteur'
            description='Recevez un code par SMS à chaque connexion afin de renforcer la sécurité de votre compte.'
            fieldType='ACTIVATE'
            actionDate={user?.oneTimePasswordActivedAt}
            actionOnClick={() => navigate(`/dashboard/authentication/doubleAuthentication`)}
          />
          <AuthSettingsItem
            title='Supprimer le compte'
            description={
              <span>
                Fonctionnalité en développement. Pour supprimer votre compte SKEZI et vos identifiants de connexion,{' '}
                <span className='font-semibold'>veuillez contacter le support</span>
              </span>
            }
            fieldType='INFO'
            actionOnClick={() => setShowContactForm(true)}
          />
        </>
      )}
    </>
  );
};

export default DashboardAuthenticationComponent;
