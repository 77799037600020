import { createContext, FC, forwardRef, RefObject, useEffect, useImperativeHandle, useState } from 'react';
import * as Icon from '../icons';
import { isSkeziaError } from '../../constants/types';
import { t } from '../../i18n';
import { v4 as uuidv4 } from 'uuid';

export const ToastQueueRefContext = createContext<RefObject<ToastQueueFunc> | null>(null);

export type ToastProps = {
  timer?: number;
  remove: (id: string) => void;
  description?: string;
  type: 'SUCCESS' | 'ERROR';
  dark?: boolean;
  id: string;
};

export const Toast: FC<ToastProps> = ({ remove, timer, description, type, id }) => {
  const [timeProgress, setTimeProgress] = useState(timer ?? 0);
  const icon = type == 'SUCCESS' ? <Icon.Check /> : <Icon.Error className='scale-[1.2]' color='#FFFFFF' />;

  useEffect(() => {
    if (!timer) return;

    const interval = setInterval(() => {
      setTimeProgress(tp => tp - 10);
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
      remove(id);
    }, timer);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className={`w-80 ${type === 'SUCCESS' ? 'bg-green-dark' : 'bg-red-primary'} flex flex-col items-start gap-y-2 rounded-lg border p-4 pb-2 shadow-lg`}
    >
      <div className='flex w-full items-center justify-between'>
        <div className='flex items-center justify-start gap-x-2'>
          {icon}
          <span className='px-2 text-sm text-white'>{description}</span>
        </div>
        <button className='scale-75' onClick={() => remove(id)}>
          <Icon.Cross className='scale-[0.85]' color='#FFFFFF' />
        </button>
      </div>
      {timer && (
        <div className='flex h-1 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700' role='progressbar'>
          <div
            className='flex w-[12%] flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-600 text-center text-xs text-white dark:bg-gray-200'
            style={{ width: `${(timeProgress / timer) * 100}%` }}
          />
        </div>
      )}
    </div>
  );
};

export type ToastQueueFunc = {
  addToast: (props: Omit<ToastProps, 'remove' | 'id'>) => void;
  displayError: (error: Error) => void;
};

export const ToastQueue = forwardRef<ToastQueueFunc>((_, ref) => {
  const [toasts, setToasts] = useState<Omit<ToastProps, 'remove'>[]>([]);

  const handleRemove = (id: string) => {
    setToasts(ts => ts.filter(toast => toast.id !== id));
  };

  useImperativeHandle(ref, () => ({
    addToast(props: Omit<ToastProps, 'remove' | 'id'>) {
      setToasts([{ ...props, id: uuidv4() }, ...toasts]);
    },
    displayError(error: Error) {
      if (!error) return;

      if (isSkeziaError(error)) {
        if (!error.displayInFront) return;
        this.addToast({ type: 'ERROR', description: t(`error.${error.type}`), timer: 5000 });
      } else {
        this.addToast({ type: 'ERROR', description: error.message, timer: 5000 });
      }
    },
  }));

  return (
    <div className='sm:w-auto sm:min-w-80 fixed right-2 top-[80px] z-50 bg-transparent'>
      <div className='flex flex-col gap-y-4'>
        {toasts.map(toast => (
          <Toast key={toast.id} remove={id => handleRemove(id)} {...toast} />
        ))}
      </div>
    </div>
  );
});

ToastQueue.displayName = 'ToastQueue';
