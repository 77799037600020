import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';
import AuthService from '../../services/auth.service';

const LogoutComponent = () => {
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    (async () => {
      try {
        await authService.logout();
      } catch (error) {
        navigate(`/login`);
      }

      const appAuth = localStorage.getItem('appAuth');
      if (appAuth && appAuth === 'franceconnect') {
        const idToken = localStorage.getItem('idToken');

        if (idToken) {
          const data = await authService.getFranceConnectLogoutLink(idToken);
          document.location.href = data.logoutUrl;
        }

        localStorage.removeItem('appAuth');
        localStorage.removeItem('idToken');
      }

      navigate(`/login`);
    })();
  }, []);

  return (
    <>
      <div className='flex h-screen items-center justify-center'>
        <Icon.Spin className='w-[6vw]' />
      </div>
      <Seo title={'Deconnexion'} />
    </>
  );
};

export default LogoutComponent;
