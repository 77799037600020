import { apiRequest, apiSkeziaRequest } from '../fetcher';
import { AppCreated, Application, EditedApp, Project, Scopes } from './types';

export default class ApiConnectionsService {
  async createApplication(name: string, projectId: string): Promise<AppCreated> {
    return apiRequest.post(`/applications/create`, { name, skeziaProjectId: projectId, scopes: [] });
  }

  async fetchApplications(): Promise<Application[]> {
    return (await apiRequest.get(`/applications`)).data;
  }

  async deleteApplication(appId: string): Promise<Application[]> {
    return (await apiRequest.delete(`/applications/${appId}/delete`)).data;
  }

  async fetchProjects(): Promise<Project[]> {
    return (
      await apiSkeziaRequest.get('/projects', {
        params: { owner: true },
      })
    ).data;
  }

  async updateSecret(appId: string): Promise<{ appSecret: string }> {
    return (await apiRequest.patch(`/applications/${appId}/update-secret`)).data;
  }

  async updateApplication(appId: string, editedApp: EditedApp): Promise<{ appId: string; name: string }> {
    return (await apiRequest.patch(`/applications/${appId}/update`, editedApp)).data;
  }

  async fetchScopes(): Promise<Scopes> {
    return (await apiRequest.get(`/applications/scopes`)).data;
  }
}
