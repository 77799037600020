import * as Yup from 'yup';

export const requestSchema = Yup.object({
  email: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Le format attendu est de type email. Ex : azerty@skezi.eu',
    ),
});
export type requestType = Yup.InferType<typeof requestSchema>;

export const resetNicknameSchema = Yup.object({
  nickname: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .min(5, 'Votre identifiant doit avoir un minimum de 5 caractères')
    .max(32, 'Votre identifiant doit avoir au maximum 32 caractères')
    .matches(
      /^(?!.*\.\.)([a-z0-9_.]+)$/,
      'Votre identifiant ne peut contenir que des lettres minuscules, des chiffres, des underscores et des points, sans points consécutifs',
    ),
});
export type resetNicknameType = Yup.InferType<typeof resetNicknameSchema>;
