import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { doubleAuthenticationSchema, doubleAuthenticationType } from '../../schema/DashboardSchema';
import UsersService from '../../services/users.service';
import { DashboardButton } from '../../components/common/Button';
import AuthSettingsItemHeader from '../../components/common/AuthSettingsItemHeader';
import { InputCheckbox } from '../../components/common/Input';
import { ToastQueueRefContext } from '../../components/common/Toast';
import Info from '../../components/common/Info';
import ConfirmModal from './ConfirmModal';
import DisabledModal from './DisabledModal';

const DashboardAuthenticationDoubleAuthenticationComponent = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDisabledModalOpen, setIsDisabledModalOpen] = useState(false);
  const [isDoubleAuthEmailActivated, setIsDoubleAuthEmailActivated] = useState(false);

  const navigate = useNavigate();
  const usersService = new UsersService();
  const toastQueueRef = useContext(ToastQueueRefContext);

  const doubleAuthenticationMethods = useForm<doubleAuthenticationType>({
    resolver: yupResolver(doubleAuthenticationSchema),
    mode: 'onSubmit',
    defaultValues: {
      activeSmsAuthentication: false,
      cellphone: '',
    },
  });

  const updateOTP = async ({ activeSmsAuthentication, cellphone }: doubleAuthenticationType) => {
    await usersService.updateDoubleAuthentication(activeSmsAuthentication, cellphone).then(() =>
      toastQueueRef?.current?.addToast({
        type: 'SUCCESS',
        description: 'Authentification à double facteur mise à jour',
        timer: 5000,
      }),
    );

    navigate('/dashboard/authentication');
  };

  const updateDoubleAuthEmail = async (isDoubleAuthEmailActivated: boolean) => {
    await usersService.updateDoubleAuthEmail(isDoubleAuthEmailActivated).then(() =>
      toastQueueRef?.current?.addToast({
        type: 'SUCCESS',
        description: 'Authentification à double facteur mise à jour',
        timer: 5000,
      }),
    );
  };

  useEffect(() => {
    (async () => {
      const { data: user } = await usersService.getUserData();
      doubleAuthenticationMethods.setValue('activeSmsAuthentication', user.oneTimePasswordActivated ?? undefined);
      doubleAuthenticationMethods.setValue('cellphone', user.cellphone);
      setIsDoubleAuthEmailActivated(user.doubleAuthEmailActivated);
    })();
  }, []);

  return (
    <FormProvider {...doubleAuthenticationMethods}>
      <form onSubmit={doubleAuthenticationMethods.handleSubmit(updateOTP)}>
        <AuthSettingsItemHeader title='Protéger votre compte avec l’authentification à double facteur' description='' />
        <Controller
          name='activeSmsAuthentication'
          control={doubleAuthenticationMethods.control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <InputCheckbox
              label={<span className='text-lg font-bold text-blue-primary'>Activer l’authentification à double facteur par SMS</span>}
              checked={value}
              color='#F6A436'
              actionOnClick={() => onChange(!value)}
              errorMessage={error?.message}
            />
          )}
        />
        <div className='mt-4 text-blue-primary'>
          Recevez un code par SMS à chaque connexion afin de renforcer la sécurité de votre compte.
          <br />
          Ce code permet de s’assurer que vous êtes bien la personne qui souhaite se connecter.
        </div>
        <div className='mt-4 w-1/2 max-md:w-full'>
          <div className='flex w-full'>
            <div className='w-1/4 font-bold text-blue-primary'>Pays</div>
            <div className='w-3/4 font-bold text-blue-primary'>Numéro de mobile</div>
          </div>
          <div className='mt-2 w-full'>
            <button
              type='button'
              className='w-1/4 rounded-l-lg border border-gray-tertiary bg-white-light py-4 pl-2 text-left text-[#9CABCB]'
            >
              +33
            </button>
            <Controller
              name='cellphone'
              control={doubleAuthenticationMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <input
                    type='text'
                    maxLength={10}
                    className='w-3/4 rounded-r-lg border border-gray-tertiary bg-white-light py-4 pl-2'
                    placeholder='00 00 00 00 00'
                    onChange={onChange}
                    value={value}
                  />
                  <div className='pt-2 font-medium text-red-primary'>{error?.message}</div>
                </>
              )}
            />
          </div>
        </div>
        <div className='mt-6 flex justify-end'>
          <DashboardButton type='cancel' onClick={() => navigate('/dashboard/authentication')} />
          <DashboardButton type='confirm' />
        </div>
        <hr className='my-10 h-px w-full border-0 bg-gray-tertiary' />

        {isDoubleAuthEmailActivated ? (
          <div className='mb-20'>
            <p className='mb-3 text-lg font-bold text-blue-primary'>Authentification double facteur par mail</p>
            <div className='flex flex-col space-y-3 min-md:flex-row min-md:justify-between min-md:space-y-0'>
              <Info
                color='#F6A436'
                title='Cette méthode est peu recommandée :'
                content="Optez plutôt pour l'authentification à double facteur par SMS afin de sécuriser davantage votre connexion."
                className='max-w-[600px] min-md:mr-10'
              />
              <button className='text-red-500 underline' type='button' onClick={() => setIsDisabledModalOpen(true)}>
                Désactiver
              </button>
            </div>
          </div>
        ) : (
          <div className='mb-20 flex flex-col space-y-8 min-md:flex-row min-md:justify-between min-md:space-y-0'>
            <div className='flex flex-col space-y-3'>
              <p className='text-lg font-bold text-blue-primary'>Authentification double facteur par mail</p>
              <p className='text-blue-primary'>Activez ou désactivez l’envoi d’un code de sécurité par e-mail.</p>
            </div>
            <button
              type='button'
              className='max-h-max rounded-full bg-orange-primary px-5 py-2 font-semibold text-white'
              onClick={() => setIsConfirmModalOpen(true)}
            >
              Activer
            </button>
          </div>
        )}
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          setIsOpen={setIsConfirmModalOpen}
          setIsDoubleAuthActivated={setIsDoubleAuthEmailActivated}
          updateDoubleAuth={updateDoubleAuthEmail}
        />
        <DisabledModal
          isOpen={isDisabledModalOpen}
          setIsOpen={setIsDisabledModalOpen}
          setIsDoubleAuthActivated={setIsDoubleAuthEmailActivated}
          updateDoubleAuth={updateDoubleAuthEmail}
        />
      </form>
    </FormProvider>
  );
};

export default DashboardAuthenticationDoubleAuthenticationComponent;
