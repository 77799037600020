export default {
  error: {
    UNKNOWN_ERROR: 'Erreur inconnue. Si le problème persiste, merci de contacter le support',
    INVALID_INPUTS: 'Les données envoyées dans le formulaire ne sont pas valides',
    EMAIL_ALREADY_USED: 'Email déjà utilisé',
    INVALID_CREDENTIALS:
      'La combinaison de votre adresse e-mail et mot de passe est incorrecte. Assurez vous de vous être inscrit, ou d’avoir saisi les bons identifiants',
    ACCOUNT_NOT_CONFIRMED: 'Vous devez confirmer votre compte avant de pouvoir vous connecter',
    INVALID_CODE: 'Code invalide',
    INVALID_TOKEN: "Le Token fourni n'est pas valide",
    INVALID_PASSWORD: 'Mot de passe invalide',
    INVALID_OLD_PASSWORD: 'Le mot de passe actuel n’est pas valide',
    INVALID_AUTHORIZATION_TOKEN: "Le token d'autorisation n'est pas valide",
    APPLICATION_NOT_FOUND: 'Application non trouvée',
    INVALID_SECRET: 'Secret invalide',
    NICKNAME_ALEREADY_USED: 'Identifiant déjà utilisé',
    NO_VALID_SESSION: "Vous n'êtes pas connecté ou votre session a expiré",
    OTP_COOLDOWN: 'Veuillez patienter quelques secondes avant de demander un nouveau code',
  },
};
