import { useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BasicButton } from '../../components/common/Button';
import * as Icons from '../../components/icons';
import ApiConnectionsService from '../../services/apiConnections/apiConnections.service';
import { Application } from '../../services/apiConnections/types';
import NewConnectionModal from './NewConnectionModal';

const ApiConnections = () => {
  const navigate: NavigateFunction = useNavigate();
  const apiConnectionsService = new ApiConnectionsService();
  const [isNewConnectionModalOpen, setIsNewConnectionModalOpen] = useState<boolean>(false);

  const [applications, setApplications] = useState<Application[]>([]);
  useMemo(async () => {
    const apps = await apiConnectionsService.fetchApplications();
    setApplications(apps);
  }, []);

  const deleteOneApplication = async (e: React.MouseEvent<HTMLButtonElement>, appId: string) => {
    e.preventDefault();
    e.stopPropagation();
    await apiConnectionsService.deleteApplication(appId);
    setApplications(applications.filter(app => app.appId !== appId));
  };

  return (
    <>
      <div className='m-2 flex w-full flex-col items-center justify-start gap-4 rounded-2xl border-[1px] border-gray-tertiary bg-white p-8'>
        <h3 className='w-full text-xl font-semibold text-green-dark'>Connexions API</h3>
        <p className='w-full text-lg text-green-dark'>Connecter votre compte à celui d&apos;autres solutions</p>
        <div className='mt-2 w-full border-b-[1px] border-b-gray-tertiary' />
        <BasicButton
          className='!mt-4 self-start font-medium'
          label='Nouvelle connexion API'
          template='orange'
          fullRounded
          onClick={() => setIsNewConnectionModalOpen(true)}
        />
        {!!applications.length && (
          <ul className='mt-6 flex w-full flex-col items-center justify-start'>
            {applications.map((app, i) => (
              <div className='w-full' key={i} onClick={() => navigate(`/dashboard/api-connections/${app.appId}`)}>
                <li className='flex w-full cursor-pointer flex-row items-center justify-between rounded-lg px-4 py-3 hover:bg-green-primary hover:bg-opacity-10'>
                  <span className='font-semibold text-green-dark'>{app.name}</span>
                  <button onClick={e => deleteOneApplication(e, app.appId)}>
                    <Icons.Trash color='#D82F2F' className='opacity-50 hover:opacity-100' />
                  </button>
                </li>
                {i != applications.length - 1 && <div className='my-2 w-full border-b-[1px] border-b-gray-tertiary' />}
              </div>
            ))}
          </ul>
        )}
      </div>
      <NewConnectionModal
        isNewConnectionModalOpen={isNewConnectionModalOpen}
        setIsNewConnectionModalOpen={setIsNewConnectionModalOpen}
        applications={applications}
        setApplications={setApplications}
      />
    </>
  );
};

export default ApiConnections;
