import { FC, useState } from 'react';
import * as Icon from '../icons';

interface ConnexionFormHeaderProps {
  topLabel?: string;
  actionTopLabel?: string;
  actionTopOnClick?: () => void;
  title: string;
  subTitle?: string;
  subSubTitle?: string;
  proSanteConnect?: boolean;
  franceConnect?: boolean;
  getProSanteConnectAuthenticationUrl?: () => void;
  getFranceConnectAuthenticationUrl?: () => void;
}

const FormHeader: FC<ConnexionFormHeaderProps> = ({
  topLabel,
  actionTopLabel,
  actionTopOnClick,
  title,
  subTitle,
  subSubTitle,
  proSanteConnect = false,
  franceConnect = false,
  getProSanteConnectAuthenticationUrl,
  getFranceConnectAuthenticationUrl,
}) => {
  const [franceConnectButtonHover, setFranceConnectButtonHover] = useState<boolean>(false);
  const fromSkemeet = localStorage.getItem('redirectUrl')?.includes(import.meta.env.VITE_SKEMEET_FRONT_URL);

  return (
    <div className='flex flex-col gap-8'>
      {topLabel && (
        <div className='break-words rounded-lg bg-[#EFFAF5] px-7 py-2 text-center'>
          {topLabel}&nbsp;
          <span className='cursor-pointer font-bold text-green-dark hover:underline' onClick={actionTopOnClick}>
            {actionTopLabel}
          </span>
        </div>
      )}
      <div className='text-center text-2xl font-bold text-green-dark'>{title}</div>
      {subTitle && <div className='mb-4 text-center text-black-light'>{subTitle}</div>}
      {subSubTitle && <div className='text-center text-gray-primary'>{subSubTitle}</div>}
      {(proSanteConnect || franceConnect) && (
        <>
          <div className={`flex ${franceConnect && !fromSkemeet ? '' : 'justify-center'} max-lg:flex-col max-lg:items-center`}>
            {franceConnect && !fromSkemeet && (
              <div className='flex flex-col max-lg:w-full min-lg:w-3/6 min-lg:border-r-2'>
                <div className='text-center font-semibold'>Avec France Connect +</div>
                <button
                  className='mt-5 flex justify-center'
                  onMouseEnter={() => setFranceConnectButtonHover(true)}
                  onMouseLeave={() => setFranceConnectButtonHover(false)}
                  onClick={() => {
                    if (getFranceConnectAuthenticationUrl) {
                      getFranceConnectAuthenticationUrl();
                    }
                  }}
                >
                  <Icon.FranceConnectPlus backgroundColor={franceConnectButtonHover ? '#1212FF' : '#000091'} />
                </button>
                <div className='mb-6 mt-1 flex cursor-pointer justify-center text-[#000091] decoration-1 hover:decoration-2'>
                  <a
                    className='mx-[1rem] mt-[0.75rem] flex border-b-[1px] border-[#000091] text-center text-[0.850rem] hover:border-b-2'
                    href='https://franceconnect.gouv.fr/franceconnect-plus'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='flex max-xl:hidden'>
                      <span className='h-full text-[0.875rem] leading-6'>Qu&apos;est-ce que FranceConnect+ ?</span>
                      <Icon.FranceConnectWhat className='ml-1 h-full' />
                    </div>
                    <div className='flex flex-col min-xl:hidden'>
                      <div className='h-full text-[0.875rem] leading-6'>Qu&apos;est-ce que</div>
                      <div className='flex'>
                        FranceConnect+ ?<Icon.FranceConnectWhat className='ml-1 h-full' />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            )}
            {proSanteConnect && (
              <div className={`flex flex-col ${franceConnect && !fromSkemeet ? 'max-lg:w-full min-lg:w-3/6' : ''} mb-6`}>
                <div className='text-center font-semibold'>Prochainement avec e-CPS</div>
                <div className='mt-5 flex justify-center'>
                  <button
                    className='w-56 cursor-not-allowed'
                    title={`Ce service n’est pas disponible pour le moment.`}
                    onClick={() => {
                      if (import.meta.env.VITE_APP_URL.includes('.dev') || import.meta.env.VITE_APP_URL.includes('localhost')) {
                        if (getProSanteConnectAuthenticationUrl) {
                          getProSanteConnectAuthenticationUrl();
                        }
                      }
                    }}
                  >
                    <Icon.ProSanteConnect color='#6F7072' />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='border-b-2' />
            <div className='flex justify-center'>
              <span className='relative bottom-3.5 bg-white px-4 font-bold text-gray-primary'>ou</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FormHeader;
