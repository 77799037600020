import { UserCreate, UserInfo } from '../constants/types';
import { apiRequest } from './fetcher';
export default class UsersService {
  async createUser(user: UserCreate): Promise<void> {
    return apiRequest.post(`/users/create`, {
      email: user.email,
      nickName: user.nickName,
      password: user.password,
      subscribeNewsletter: user.subscribeNewsletter,
      locationUrl: window.location.href,
    });
  }

  async getUserData(display?: boolean): Promise<{ data: UserInfo }> {
    return apiRequest.get('/users/me', display != undefined ? { params: { display } } : {});
  }

  async sendEmailValidation(email: string): Promise<void> {
    return apiRequest.post(`/users/send-email-validation`, {
      email,
    });
  }

  async sendEmailMergeValidation(email: string): Promise<void> {
    return apiRequest.post(`/users/send-email-merge-validation`, {
      email,
    });
  }

  async validateAccount(updateEmailToken: string): Promise<void> {
    return apiRequest.post(`/users/validate-account`, { updateEmailToken });
  }

  async updateEmail(data: { email: string }) {
    return apiRequest.post('/users/update-email', data);
  }

  async updateNickname(data: { nickName: string }) {
    return apiRequest.post('/users/update-nickname', data);
  }

  async updatePassword(data: { oldPassword: string; newPassword: string }) {
    return apiRequest.patch(`/users/password`, data);
  }

  async updateDoubleAuthentication(oneTimePasswordActivated: boolean, cellphone: string): Promise<void> {
    return apiRequest.post(`/users/update-double-authentication`, {
      oneTimePasswordActivated,
      cellphone,
    });
  }

  async updateDoubleAuthEmail(isDoubleAuthEmailActivated: boolean): Promise<void> {
    return apiRequest.put(`/users/update-double-authentication-email`, { doubleAuthEmailActivated: isDoubleAuthEmailActivated });
  }

  async sendSupportMessage(name: string, email: string, content: string): Promise<void> {
    return apiRequest.post(`/users/send-support-message`, {
      name,
      email,
      content,
    });
  }
}
