import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { AxiosError } from 'axios';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import DashboardLayout from './components/common/DashboardLayout';
import Layout from './components/common/Layout';
import { Matomo } from './components/common/Matomo';
import { SupportButton } from './components/common/SupportButton';
import './index.css';
import ApiConnections from './pages/ApiConnections/ApiConnections';
import ConnectionEdit from './pages/ApiConnections/ConnectionEdit';
import CheckUser from './pages/CkeckUser/CheckUser';
import DashboardApps from './pages/DashboardApps/DashboardApps';
import DashboardAuthentication from './pages/DashboardAuthentication/DashboardAuthentication';
import DashboardAuthenticationDoubleAuthentication from './pages/DashboardAuthenticationDoubleAuthentication/DashboardAuthenticationDoubleAuthentication';
import DashboardAuthenticationEmail from './pages/DashboardAuthenticationEmail/DashboardAuthenticationEmail';
import DashboardAuthenticationNickName from './pages/DashboardAuthenticationNickName/DashboardAuthenticationNickName';
import DashboardAuthenticationPassword from './pages/DashboardAuthenticationPassword/DashboardAuthenticationPassword';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import ResetNickname from './pages/ResetNickname/ResetNickname';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Subscription from './pages/Subscription/Subscription';
import reportWebVitals from './reportWebVitals';
import { useCallback, useRef } from 'react';
import { ToastQueue, ToastQueueFunc, ToastQueueRefContext } from './components/common/Toast';
import { apiRequest } from './services/fetcher';
import { PrivateRoute } from './components/common/PrivateRoute';

const App = () => {
  const toastQueueRef = useRef<ToastQueueFunc>(null);

  const interceptor = useCallback(
    async (error: Error) => {
      if (error instanceof AxiosError) {
        toastQueueRef.current?.displayError(error.response?.data);
        return Promise.reject(error.response?.data);
      } else {
        toastQueueRef.current?.displayError(error);
        return Promise.reject(error);
      }
    },
    [toastQueueRef],
  );

  apiRequest.interceptors.response.clear();
  apiRequest.interceptors.response.use(response => response, interceptor);

  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  type DashboardAuthenticationWithPropsProps<P> = {
    Component: React.ComponentType<P>;
  } & P;

  const DashboardAuthenticationWithProps = <P,>({ Component, ...rest }: DashboardAuthenticationWithPropsProps<P>) => {
    return <Component {...(rest as P)} />;
  };

  return (
    <ToastQueueRefContext.Provider value={toastQueueRef}>
      <Matomo.Wrapper>
        <Routes>
          <Route element={<Layout Outlet={Outlet} />}>
            <Route path={`/login`} Component={Login} />
            <Route path={`/subscription`} Component={Subscription} />
            <Route path={`/reset-nickname`} Component={ResetNickname} />
            <Route path={`/reset-password`} Component={ResetPassword} />
            <Route path={`/check-user`} Component={CheckUser} />
            <Route path={`/logout`} Component={Logout} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route element={<DashboardLayout />}>
              <Route path={`/dashboard/apps`} Component={DashboardApps} />
              <Route
                path={`/dashboard/authentication`}
                element={<DashboardAuthenticationWithProps Component={DashboardAuthentication} setShowContactForm={setShowContactForm} />}
              />
              <Route path={`/dashboard/authentication/email`} Component={DashboardAuthenticationEmail} />
              <Route path={`/dashboard/authentication/nickName`} Component={DashboardAuthenticationNickName} />
              <Route path={`/dashboard/authentication/password`} Component={DashboardAuthenticationPassword} />
              <Route path={`/dashboard/authentication/doubleAuthentication`} Component={DashboardAuthenticationDoubleAuthentication} />
              <Route path={`/dashboard/api-connections`} Component={ApiConnections} />
              <Route path={`/dashboard/api-connections/:appId`} Component={ConnectionEdit} />
            </Route>
          </Route>
          <Route path='/dashboard/*' element={<Navigate to='/dashboard/authentication' />} />
          <Route path='*' element={<Navigate to='/login' />} />
        </Routes>
        <SupportButton showContactForm={showContactForm} setShowContactForm={setShowContactForm} />
        <ToastQueue ref={toastQueueRef} />
      </Matomo.Wrapper>
    </ToastQueueRefContext.Provider>
  );
};

const app = document.getElementById('root') as HTMLElement;
const root = createRoot(app);

root.render(
  <Matomo.Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Matomo.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
