import React, { FC, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import UsersService from '../../services/users.service';
import { UserInfo } from '../../constants/types';

export const UserContext = React.createContext<UserInfo | null>(null);

export const PrivateRoute: FC = () => {
  const usersService = new UsersService();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserInfo | null>(null);

  useLayoutEffect(() => {
    (async () => {
      try {
        const { data: fetchedUser } = await usersService.getUserData(false);
        setUser(fetchedUser);
      } catch (error) {
        navigate('/logout', { replace: true });
      }
    })();
  }, [location.pathname]);

  return (
    <UserContext.Provider value={user}>
      <Outlet />
    </UserContext.Provider>
  );
};
